import colors from 'Application/theme/colors';

export const statuses = {
	IN_PROGRESS: 'IN_PROGRESS',
	ON_HOLD: 'ON_HOLD',
	CLOSED: 'CLOSED',
	OPERATION: 'OPERATION',
	MATURE: 'MATURE',
	EXCLUDED: 'EXCLUDED',
	CANCELLED: 'CANCELLED',
};

export const statusesOptions = [
	{
		label: 'In progress',
		value: statuses.IN_PROGRESS,
	},
	{
		label: 'On hold',
		value: statuses.ON_HOLD,
	},
	{
		label: 'Closed',
		value: statuses.CLOSED,
	},
	{
		label: 'In operation',
		value: statuses.OPERATION,
	},
	{
		label: 'Matured',
		value: statuses.MATURE,
	},
	{
		label: 'Excluded',
		value: statuses.EXCLUDED,
	},
	{
		label: 'Cancelled',
		value: statuses.CANCELLED,
	},
];

export const statusesColors = {
	[statuses.IN_PROGRESS]: colors.primary.dark,
	[statuses.ON_HOLD]: colors.common.brown,
	[statuses.CLOSED]: 'rgba(102,102,102,0.6)',
	[statuses.CANCELLED]: 'rgba(102,102,102,0.6)',
	[statuses.OPERATION]: colors.primary.main,
	[statuses.MATURE]: colors.secondary.main,
	[statuses.EXCLUDED]: colors.error.light,
};
